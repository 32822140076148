.Body {
}

.section-style {
    /*background-color: #4B515D;*/
}

.custom-card {
    color: #4B515D;
    border-radius: 35px;
}

.custom-h6 {
    color: #1C2331;
}

.custom-span {
    color: #868B94
}

.custom-icon {
    color: #868B94;
}

.custom-card-body {
    font-size: 1rem;
}

.custom-card-body-div {
    background-color: rgba(190, 216, 232, .5);
}